var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout text-center" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-small-size-100" },
      [
        _c(
          "lock-card",
          [
            _c("img", {
              staticClass: "img",
              attrs: { slot: "imageProfile", src: _vm.image },
              slot: "imageProfile",
            }),
            _c(
              "h4",
              { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
              [_vm._v("Tania Andrew")]
            ),
            _c(
              "md-field",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("label", [_vm._v("Enter Password")]),
                _c("md-input", {
                  attrs: { type: "password" },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
              ],
              1
            ),
            _c(
              "md-button",
              {
                staticClass: "md-success md-round",
                attrs: { slot: "footer", href: "" },
                slot: "footer",
              },
              [_vm._v("Unlock")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }